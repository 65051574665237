import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import Aerodynamisch from '../images/cla-coupe/highlights/aerodynamisch.jpeg'
import Details from '../images/cla-coupe/highlights/details.jpeg'
import Intelligent from '../images/cla-coupe/highlights/intelligent.jpeg'
import Millimeter from '../images/cla-coupe/highlights/millimeter.jpeg'

import HeaderImage from '../images/cla-coupe/header-cla-coupe.jpeg'

import InterieurImage from '../images/cla-coupe/gallery/interieur-teaser-cla-coupe.jpeg'
import ExterieurImage from '../images/cla-coupe/gallery/exterieur-teaser-cla-coupe.jpeg'

import InterieurImageGallery1 from '../images/cla-coupe/gallery/gallery1-interieur-cla-coupe.jpeg'
import InterieurImageGallery2 from '../images/cla-coupe/gallery/gallery2-interieur-cla-coupe.jpeg'
import InterieurImageGallery3 from '../images/cla-coupe/gallery/gallery3-interieur-cla-coupe.jpeg'
import InterieurImageGallery4 from '../images/cla-coupe/gallery/gallery4-interieur-cla-coupe.jpeg'
import InterieurImageGallery5 from '../images/cla-coupe/gallery/gallery5-interieur-cla-coupe.jpeg'

import ExterieurImageGallery1 from '../images/cla-coupe/gallery/gallery1-exterieur-cla-coupe.jpeg'
import ExterieurImageGallery2 from '../images/cla-coupe/gallery/gallery2-exterieur-cla-coupe.jpeg'
import ExterieurImageGallery3 from '../images/cla-coupe/gallery/gallery3-exterieur-cla-coupe.jpeg'
import ExterieurImageGallery4 from '../images/cla-coupe/gallery/gallery4-exterieur-cla-coupe.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
  InterieurImageGallery5,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
]

const CLACoupe = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>(Luft-)Widerstand zwecklos. Das CLA Coupé.</H1>
      <p>
        Atemberaubend sportliche Proportionen und die kraftvoll-dynamische
        Designsprache mit dem Wechselspiel konkaver/konvexer Flächen machen das
        viertürige Coupé unverwechselbar. Das breitenbetonte Heck strahlt Kraft
        und Athletik aus und zeigt sich in einem Wechselspiel aus
        konvex-konkaven Flächen und Kanten. Das flach abfallende Dach und die
        starke, charakteristische Rundung der Heckscheibe verleihen dem CLA auch
        von hinten einen coupéhaften Charakter.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>
        Meister der Aerodynamik. Der CLA ist beides: strömungsgünstig und
        atemberaubend.
      </H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={Aerodynamisch}
          title="Aerodynamische Perfektion."
          headline="Aerodynamische Perfektion."
          description="Auch wenn es nur um Tausendstel geht – es ist ein harter Kampf. Seit 100 Jahren arbeitet Mercedes-Benz daran, perfekt aerodynamische Karosserien in Serie herzustellen. Gekrönt wird dieses Engagement nun mit dem CLA: Mit einem Rekord cw-Wert von 0,23 und einer Luftwiderstandsfläche cw x A mit 0,51 m² ist er der Meister der Aerodynamik. 
          Je kleiner der Luftwiderstand des Fahrzeugs ist, desto geringer wird auch der Kraftstoffverbrauch. Dadurch beeinflusst jedes Tausendstel des cw-Werts die Effizienz des Fahrzeugs.a"
        />
        <ImageBox
          imagesrc={Intelligent}
          title="Intelligent umströmt."
          headline="Intelligent umströmt."
          description="Mittels Aerodynamik kann das Fahrzeug nicht nur effizienter, sondern auch leiser werden. Wie groß der Effekt wirklich ist, weiß Dr. Teddy Woll, Leiter der Abteilungen Aerodynamik, Aeroakustik und des Windkanalzentrums. „Die Rechnung ist einfach: Eine Verbesserung des Luftwiderstands um 10 Punkte bedeutet eine Ersparnis von rund 0,1 Litern Kraftstoff. Bei einer Autobahnfahrt kann so rund ein Viertel Liter Kraftstoff pro 100 Kilometer gespart werden. Wollte man diesen Effekt durch Leichtbaumaßnahmen erzielen, müsste das Fahrzeug rund 35 Kilogramm leichter werden. Aber auch Windgeräusche sind eine Disziplin der Aerodynamik. Weniger Luftverwirbelungen bedeuten weniger Geräusche im Fahrzeug. Und mit dem CLA haben wir nicht nur das strömungsgünstigste, sondern auch das im Innenraum leiseste Fahrzeug seiner Klasse.“"
        />
        <ImageBox
          imagesrc={Details}
          title="Ausgefeilte Details."
          headline="Ausgefeilte Details."
          description="Gekonnt gleitet der CLA durch die Luftströmung: Durch zahlreiche technologische Innovationen haben es die Ingenieure geschafft, den Luftwiderstand auf ein Minimum zu reduzieren. Wie beispielsweise durch den Kühler, der mit einer Lamellen-Jalousie versehen ist, die sich nur dann öffnet, wenn der Motor nach entsprechender Kühlung verlangt. „Wir konnten die Führung des Luftstroms unter dem Fahrzeug verbessern, indem wir den Unterboden großflächig verkleidet und im mittleren Bereich der Hinterachse mit einem aerodynamisch optimierten Endschalldämpfer sowie einem Diffusor versehen haben“, erklärt Dr. Woll.
          „Außerdem konnten wir mit speziellen Aerodynamikrädern und gezackten Radspoilern die Umströmung der Räder deutlich reduzieren.
          Hinzu kommt unser dynamisches Heck mit den Abrisskanten an Heckdeckel und Heckleuchten, mit dem wir die Strömung noch besser hinter dem Fahrzeug bündeln konnten.“ Die Herausforderung dabei ist es, eine optimale Strömungsfähigkeit mit einer perfekt designten Form zu vereinen."
        />
        <ImageBox
          imagesrc={Millimeter}
          title="„Es geht oft um Millimeter.“"
          headline="„Es geht oft um Millimeter.“"
          description="Aerodynamik und Design gehen Hand in Hand. Schon im frühen Entwicklungsstadium arbeitet Dr. Woll mit den Designern zusammen. „Bereits bei den ersten 1:4 Modellen legen wir gemeinsam Proportionen fest und feilen im Windkanal an der Aerodynamik. Da ist eine gute Zusammenarbeit wichtig, denn es geht oft um Millimeter. Wie man am CLA sehr schön sehen kann, gibt es keinen Widerspruch zwischen guter Aerodynamik und tollem Design. Das Gesamtkonzept ist hierbei wichtig und dieses ist auch der Grund, warum nicht in allen Baureihen Rekord cw-Werte des CLA erreicht werden können.“, erklärt der Aerodynamikexperte. „Dennoch werden auch die weiteren Modelle unserer Kompaktwagen-Familie Spitzenwerte beim cw-Wert erreichen.“ Bestrebt noch strömungsgünstigere Modelle zu bauen, geht die Jagd der Ingenieure nach optimierbaren Details weiter: mit dem Ziel, die Bestmarke wieder einmal zu knacken."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default CLACoupe
